<template>
  <div>
    <header>
      <!-- BARRA DE COMPARTILHAMENTO -->
      <div class="voltar-e-social bg-theme-secundaria">
        <v-container py-2>
          <v-layout class="justify-space-between">
            <div class="display-flex align-center">
              <router-link :to="{ name: routes.web.ProjectLP, query: { projectId: projectLP.id }}">
                <Icon class="far fa-long-arrow-left mr-2" size="16" colorFont="#fff" />voltar ao projeto
              </router-link>
            </div>
            <div class="display-flex align-center">
              <span class="compartilhe">compartilhe</span>
              <a :href="GetSocialMediaLink(SocialMedia.Facebook, null, 'vazio', shareLink)">
                <Icon class="fab fa-facebook-f mx-3" size="18" />
              </a>
              <a :href="GetSocialMediaLink(SocialMedia.Whatsapp, null, 'vazio', shareLink)">
                <Icon class="fab fa-whatsapp mx-3" size="18" />
              </a>
              <a :href="GetSocialMediaLink(SocialMedia.Linkedin, null, 'vazio', shareLink)">
                <Icon class="fab fa-linkedin-in mx-3" size="18" />
              </a>
              <a :href="GetSocialMediaLink(SocialMedia.Twitter, null, 'vazio', shareLink)">
                <Icon class="fab fa-twitter mx-3" size="18" />
              </a>
              <!-- <a href="#">
                            <Icon class="fab fa-instagram mx-3" size="18" />
              </a>-->
              <a :href="`mailto:?subject=${projectLP.fullName}&body=${shareLink}`" target="_blank">
                <Icon class="fas fa-envelope mx-3" size="18" />
              </a>
              <!-- <a href="#">
                            <Icon class="far fa-link ml-3" size="18" />
              </a>-->
            </div>
          </v-layout>
        </v-container>
      </div>
      <!-- TITULO DA APLICACAO -->
      <div class="title-prestacao-contas bg-theme-primaria">
        <v-container display-flex justify-space-between align-center>
          <div>
            <h4 class="font-principal">PRESTAÇÃO DE CONTAS</h4>
            <h1 class="font-destaque">
              {{projectLP.fullName}}
              <span
                class="status-pequeno color-theme-primaria"
              >{{projectLP.projectPhaseDesc}}</span>
            </h1>
          </div>
          <div class="logo-direito">
            <FileImage :file="projectLP.institutionFile" :useBg="false" alt="Logo instituição" />
          </div>
        </v-container>
      </div>
    </header>
    <div class="account-hero" v-if="projectLP.projectAccountabilityHeroConfig">
      <FileImage :file="projectLP.projectAccountabilityHeroConfig.heroFile" />
      <div class="txt-blk">
        <h1 class="color-theme-primaria">{{projectLP.projectAccountabilityHeroConfig.primaryText}}</h1>
        <p>{{projectLP.projectAccountabilityHeroConfig.supportText}}</p>
        <v-layout class="anchor-menu">
          <a @click="scrollTo('performanceBlock')" class="color-theme-primaria">Performance</a>
          <a v-if="showGoals" @click="scrollTo('goalsBlock')" class="color-theme-primaria">Metas</a>
          <a v-if="showActivities" @click="scrollTo('activitiesBlock')" class="color-theme-primaria">Atividades</a>
        </v-layout>
      </div>
    </div>
  </div>
</template>
<script type="plain/text">
import CommonHelper from "@/scripts/helpers/common.helper";
import FileImage from "@/components/FileImage.vue";
import { Routes } from "@/scripts/models/enums/routes.enum";
import {
  SocialMedia,
  GetSocialMediaLink
} from "@/scripts/models/enums/socialMedias.enum";
export default {
  components: {
    FileImage
  },
  props: ["projectLP", "showGoals", "showActivities"],
  data() {
    return {
      SocialMedia: SocialMedia,
      GetSocialMediaLink: GetSocialMediaLink,
      routes: Routes
    };
  },
  computed: {
    shareLink() {
      return window.location.href;
    }
  },
  methods: {
    scrollTo(block) {
      this.$emit("scroll", block);
    }
  }
};
</script>