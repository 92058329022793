<template>
  <v-container id="atividades">
    <v-layout>
      <v-flex>
        <h2
          class="titulo-do-bloco font-destaque color-theme-primaria"
        >Execução do cronograma de atividades</h2>
      </v-flex>
    </v-layout>
    <v-layout row wrap>
      <v-flex>
        <v-card>
          <v-container>
            <template v-for="activity in projectLP.scheduleList">
              <v-list class="lista-horiz box-list-activity" :key="activity.id">
                <v-layout>
                  <div class="mr-4 t-767-center">
                    <FileImage :file="activity.file" :alt="''" />
                  </div>
                  <!-- <template v-slot:placeholder>
                    <v-row class="fill-height ma-0" align="center" justify="center">
                      <v-progress-circular indeterminate color="grey lighten-5"></v-progress-circular>
                    </v-row>
                  </template>-->
                  <v-flex ml-2>
                    <h3 class="color-theme-primaria">{{activity.name}}</h3>
                    <small class="color-theme-padrao">{{getPeriodDate(activity)}}</small>
                    <p class="color-theme-texto">{{activity.description}}</p>
                    <div style="margin-left:-8px">
                      <!-- agendado -->
                      <v-btn outline class="square no-click" :color="getButtonCollor(activity)">
                        <v-icon size="16" class="mr-2">{{getIconName(activity)}}</v-icon>
                        {{getStatusName(activity)}}
                      </v-btn>
                    </div>
                  </v-flex>
                </v-layout>
                <!-- v-if="activity.schedulePeriodList && activity.schedulePeriodList.length > 0" -->
                <div class="section image-grid grayscale mt-3" style="padding:0!important">
                  <div style="position:relative">
                    <div
                      class="wrapper px-2"
                      style="margin:0px;justify-content: flex-start; background:none;"
                    >
                      <template
                        v-for="(schedulePeriodFile, index) in activity.schedulePeriodList[0].schedulePeriodFiles"
                      >
                        <div
                          class="item my-2"
                          style="max-height:100px;max-width:110px;min-height:100px;min-width:110px;"
                          v-if="schedulePeriodFile.file"
                          :key="schedulePeriodFile.id"
                        >
                          <div class="tools">
                            <a
                              class="fal fa-search-plus"
                              @click="openGalleryLightbox(activity.schedulePeriodList[0].schedulePeriodFiles, index)"
                            ></a>
                          </div>
                          <FileImage :file="schedulePeriodFile.file" />
                        </div>
                        <div
                          class="item"
                          style="max-height:100px;max-width:110px;min-height:100px;min-width:110px;"
                          v-else-if="schedulePeriodFile.video"
                          :key="schedulePeriodFile.id"
                        >
                          <div class="tools">
                            <a class="fal fa-video" @click="openVideoModal(schedulePeriodFile.video)"></a>
                          </div>
                          <FileImage :file="getThumb(schedulePeriodFile.video)" />
                        </div>
                      </template>
                    </div>
                  </div>
                </div>
              </v-list>
            </template>
          </v-container>
        </v-card>
      </v-flex>
    </v-layout>
    <PlayVideo ref="videoPlayerComponent" ></PlayVideo>
    <vue-easy-lightbox
      :visible="galleryLightboxVisible"
      :imgs="getGalleryLightboxImgs()"
      :index="galleryLightboxIndex"
      @hide="galleryLightboxVisible = false"
    ></vue-easy-lightbox>
  </v-container>
</template>
<script type="plain/text">
import FileImage from "@/components/FileImage.vue";
import PlayVideo from "@/components/PlayVideo.vue";
import CommonHelper from "@/scripts/helpers/common.helper";
import {
  SchedulePeriodStatus,
  GetSchedulePeriodStatus
} from "@/scripts/models/enums/schedulePeriodStatus.enum";
export default {
  components: {
    FileImage,
    PlayVideo
  },
  props: ["projectLP"],
  data() {
    return {
      SchedulePeriodStatus: SchedulePeriodStatus,
      currentGallery: [],
      galleryLightboxVisible: false,
      galleryLightboxIndex: 0
    };
  },
  computed: {},
  methods: {
    getPeriodDate(activity) {
      if (
        activity.schedulePeriodList &&
        activity.schedulePeriodList.length > 0
      ) {
        var result = this.formatDateToPTBR(
          activity.schedulePeriodList[0].initialDate
        );
        if (activity.schedulePeriodList[0].finalDate) {
          result +=
            " - " +
            this.formatDateToPTBR(activity.schedulePeriodList[0].finalDate);
        }
      }

      return result;
    },
    formatDateToPTBR(date) {
      return this.$moment(date).format("LL");
    },
    getStatusName(activity) {
      let status = this.getActivityStatus(activity);
      return GetSchedulePeriodStatus(status);
    },
    getIconName(activity) {
      let status = this.getActivityStatus(activity);
      switch (status) {
        case SchedulePeriodStatus.Scheduled:
          return "fal fa-calendar";
          break;
        case SchedulePeriodStatus.Late:
          return "fal fa-calendar-exclamation";
          break;
        case SchedulePeriodStatus.Canceled:
          return "fal fa-calendar-times";
          break;
        case SchedulePeriodStatus.Finished:
          return "fal fa-calendar-check";
          break;
        case SchedulePeriodStatus.Ontime:
          return "fal fa-calendar-alt";
          break;
      }
    },
    getButtonCollor(activity) {
      let status = this.getActivityStatus(activity);
      switch (status) {
        case SchedulePeriodStatus.Scheduled:
          return "grey lighten-1";
          break;
        case SchedulePeriodStatus.Late:
          return "orange darken-2";
          break;
        case SchedulePeriodStatus.Canceled:
          return "red";
          break;
        case SchedulePeriodStatus.Finished:
          return "green";
          break;
        case SchedulePeriodStatus.Ontime:
          return "#3377B4";
          break;
      }
    },
    getActivityStatus(activity) {
      if (
        activity.schedulePeriodList &&
        activity.schedulePeriodList.length > 0
      ) {
        return activity.schedulePeriodList[0].statusId;
      }
      return 0;
    },
    getThumb(video) {
      let videoHash = CommonHelper.validateYoutubeLink(video.link);
      let thumb = `https://img.youtube.com/vi/${videoHash}/0.jpg`;
      let file = { imageContent: thumb };
      return file;
    },
    openVideoModal(video) {
      this.$refs.videoPlayerComponent.open(video);
    },
    openGalleryLightbox(images, index) {
      this.galleryLightboxVisible = true;
      this.galleryLightboxIndex = index;
      this.currentGallery = images;
    },
    getGalleryLightboxImgs() {
      let imgs = [];
      if (this.currentGallery == null) return [];
      for (let i = 0; i < this.currentGallery.length; i++) {
        if (
          this.currentGallery[i] != null &&
          this.currentGallery[i].file != null
        )
          imgs.push(this.currentGallery[i].file.path);
      }
      return imgs;
    }
  }
};
</script>