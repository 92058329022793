const GoalFrequency = {
    Mensal: 1,
    Bimestral: 2,
    Trimestral: 3
};

const GetGoalFrequencyName = function (frequency) {
    if (frequency == GoalFrequency.Mensal) {
        return "mês";
    }
    else if (frequency == GoalFrequency.Bimestral) {
        return "bimestre";
    }
    else if (frequency == GoalFrequency.Trimestral) {
        return "trimestre";
    }
    return "";
}

export { GoalFrequency, GetGoalFrequencyName };