<template>
  <v-container grid-list-lg id="metas">
    <v-layout>
      <v-flex>
        <h2 class="titulo-do-bloco font-destaque color-theme-primaria">Prestação de contas</h2>
      </v-flex>
    </v-layout>
    <v-layout row wrap>
      <template v-for="goal in projectLP.goalList">
        <v-flex xs12 :key="goal.id">
          <v-card>
            <v-container>
              <h4 class="titulo-do-cartao color-theme-texto">{{goal.name}}</h4>
              <p class="color-theme-padrao">
                Comprovação por:
                <strong>{{goal.goalVerification.name}}</strong>
              </p>
              <div class="lista-vertical">
                <div class="progress mr-3">
                  <div
                    class="bg-theme-primaria small"
                    :style="{width: formatterHelper.formatSlider(howMuchHasBeenRealized(goal), howMuchIsExpected(goal))+'%'}"
                  ><span class="mr-2">Realizado</span>{{formatterHelper.formatSlider(howMuchHasBeenRealized(goal), howMuchIsExpected(goal), 0, 10000) }}%</div>
                </div>
                <h5>{{formatterHelper.formatSlider(howMuchHasBeenRealized(goal), howMuchIsExpected(goal)) <= 100 ? '100%' : formatterHelper.formatSlider(howMuchHasBeenRealized(goal), howMuchIsExpected(goal), 0, 10000) + '%' }}</h5>
              </div>
              <template v-for="index in numberOfFields">
                <v-list
                  v-if="index-1 < goal.goalValueList.length"
                  class="lista-vertical pt-0"
                  column
                  :key="index"
                >
                  <v-layout column>
                    <v-flex>
                        <hr class="sm my-3" :key="index" />
                        <h5>{{index + 'º ' + GetGoalFrequencyName(frequency)}}</h5>
                    </v-flex>
                    <v-flex
                      v-if="goal.goalValueList[index-1].statusId == GoalsValuesStatus.Finished"
                    >
                      <h4>{{goal.goalValueList[index-1].title}}</h4>
                      <div>{{goal.goalValueList[index-1].description}}</div>
                    </v-flex>
                    <v-flex>
                      <div class="display-flex align-items-center">
                        <div class="progress mr-3">
                          <div
                            class="bg-theme-primaria small"
                            :style="{
                              width: formatterHelper.formatSlider(goal.goalValueList[index-1].realized, goal.goalValueList[index-1].value)+'%',
                              opacity: 0.5}"
                          ><span class="mr-2">Realizado</span>{{goal.goalValueList[index-1].realized ? formatterHelper.formatThousand(goal.goalValueList[index-1].realized) : 0}}</div>
                        </div>
                        <v-tooltip top>
                          <template v-slot:activator="{on}">
                            <h5 v-on="on">{{formatterHelper.formatThousand(goal.goalValueList[index-1].value)}}</h5>
                          </template>
                          <span>Previsto para o período</span>
                        </v-tooltip>
                      </div>
                    </v-flex>
                    <v-flex
                      v-if="goal.goalValueList[index-1].goalsValuesFiles && goal.goalValueList[index-1].goalsValuesFiles.length > 0"
                    >
                      <!-- FOTOS -->
                      <template >
                        <div class="section image-grid grayscale" style="padding: 0;">
                          <div style="position:relative">
                            <div class="wrapper" style="margin:0px;justify-content: flex-start;">
                              <template
                                v-for="(goalValueFile, goalValueFileIndex) in getGoalsValuesFilesImages(goal.goalValueList[index-1].goalsValuesFiles)"
                              >
                                <div
                                  class="item"
                                  style="max-height:100px;max-width:110px;min-height:100px;min-width:110px;"
                                  v-if="goalValueFile.file"
                                  :key="goalValueFile.id"
                                >
                                  <div class="tools">
                                    <a
                                      class="fal fa-search-plus"
                                      @click="openGalleryLightbox(goal.goalValueList[index-1].goalsValuesFiles, goalValueFileIndex)"
                                    ></a>
                                  </div>
                                  <FileImage :file="goalValueFile.file" />
                                </div>
                                <div
                                  class="item"
                                  style="max-height:100px;max-width:110px;min-height:100px;min-width:110px;"
                                  v-else-if="goalValueFile.video"
                                  :key="goalValueFile.id"
                                >
                                  <div class="tools">
                                    <a
                                      class="fal fa-video"
                                      @click="openVideoModal(goalValueFile.video)"
                                    ></a>
                                  </div>
                                  <FileImage :file="getThumb(goalValueFile.video)" />
                                </div>
                              </template>
                            </div>
                          </div>
                        </div>
                      </template>
                      <template>
                        <div class="display-flex align-items-center mt-4">
                          <template v-for="gvf in getGoalsValuesFilesFiles(goal.goalValueList[index-1].goalsValuesFiles)">
                            <a
                              :href="gvf.file ? gvf.file.path : gvf.video.link"
                              target="_blank"
                              class="grupo-comprovante"
                              :key="gvf.id"
                            >
                              <i class="fal" :class="getIconName(gvf)"></i>
                              <span
                                class="color-theme-texto"
                              >{{gvf.file ? gvf.file.name : gvf.video.name}}</span>
                            </a>
                          </template>
                        </div>
                      </template>
                    </v-flex>
                  </v-layout>
                </v-list>
              </template>
            </v-container>
          </v-card>
        </v-flex>
      </template>
    </v-layout>
    <PlayVideo ref="videoPlayerComponent" ></PlayVideo>
    <vue-easy-lightbox
      :visible="galleryLightboxVisible"
      :imgs="getGalleryLightboxImgs()"
      :index="galleryLightboxIndex"
      @hide="galleryLightboxVisible = false"
    ></vue-easy-lightbox>
  </v-container>
</template>
<script type="plain/text">
import FileImage from "@/components/FileImage.vue";
import PlayVideo from "@/components/PlayVideo.vue";
import { GetGoalFrequencyName } from "@/scripts/models/enums/goalFrequency.enum";
import { GoalsValuesStatus } from "@/scripts/models/enums/goalsValuesStatus.enum";
import FormatterHelper from "@/scripts/helpers/formatter.helper";
import CommonHelper from "@/scripts/helpers/common.helper";

export default {
  components: {
    FileImage,
    PlayVideo
  },
  props: ["projectLP"],
  data() {
    return {
      GetGoalFrequencyName: GetGoalFrequencyName,
      GoalsValuesStatus: GoalsValuesStatus,
      formatterHelper: new FormatterHelper(),
      currentGallery: [],
      galleryLightboxVisible: false,
      galleryLightboxIndex: 0
    };
  },
  computed: {
    numberOfFields() {
      return CommonHelper.calculateNumberOfFieldsBasedOnProjectFrequency(
        this.frequency,
        this.projectLP.initialDate,
        this.projectLP.finalDate
      );
    },
    frequency() {
      if (
        this.projectLP &&
        this.projectLP.beneficiariesGoal &&
        this.projectLP.beneficiariesGoal.frequency
      )
        return Number(this.projectLP.beneficiariesGoal.frequency);
      return 0;
    }
  },
  methods: {
    getGoalsValuesFilesImages(goalsValuesFiles){
      let ret = [];
      let extensions = ['.jpg', '.gif', '.png', '.jpeg', '.bmp', '.tiff'];
      goalsValuesFiles.forEach(el => {
        if((el.file != null && extensions.includes(el.file.extension)) || el.video != null)
          ret.push(el);
      });
      return ret;
    },
    getGoalsValuesFilesFiles(goalsValuesFiles){
      let ret = [];
      let extensions = ['.xls', '.xlsx', '.txt', '.pdf', '.doc', '.docx', '.bin'];
      goalsValuesFiles.forEach(el => {
        if(el.file != null && extensions.includes(el.file.extension))
          ret.push(el);
      });
      return ret;
    },
    howMuchHasBeenRealized(goal) {
      if (goal && goal.goalValueList) {
        let result = goal.goalValueList.reduce((acc, value) => {
          if (
            value.statusId != GoalsValuesStatus.NotStarted &&
            !value.canceled
          ) {
            return acc + value.realized;
          }
          return acc;
        }, 0);
        return result;
      }
      return 0;
    },
    howMuchIsExpected(goal) {
      if (goal && goal.goalValueList) {
        let result = goal.goalValueList.reduce((acc, value) => {
          if (
            value.statusId != GoalsValuesStatus.NotStarted &&
            !value.canceled
          ) {
            return acc + value.value;
          }
          return acc;
        }, 0);
        return result;
      }
      return 0;
    },
    getThumb(video) {
      let videoHash = CommonHelper.validateYoutubeLink(video.link);
      let thumb = `https://img.youtube.com/vi/${videoHash}/0.jpg`;
      let file = { imageContent: thumb };
      return file;
    },
    getExtension(file) {
      let [, ext] = file.name.split(".");
      return ext;
    },
    getIconName(gvf) {
      if (gvf.file) {
        let ext = this.getExtension(gvf.file);
        if (ext.toLowerCase() == "pdf") {
          return "fa-file-pdf text-red";
        } else {
          return "fa-file-image color-green";
        }
      } else {
        return "fa-file-video text-red";
      }
    },
    openVideoModal(video) {
      this.$refs.videoPlayerComponent.open(video);
    },
    openGalleryLightbox(images, index) {
      this.galleryLightboxVisible = true;
      this.galleryLightboxIndex = index;
      this.currentGallery = images;
    },
    getGalleryLightboxImgs() {
      let imgs = [];
      if (this.currentGallery == null) return [];
      for (let i = 0; i < this.currentGallery.length; i++) {
        if (
          this.currentGallery[i] != null &&
          this.currentGallery[i].file != null
        )
          imgs.push(this.currentGallery[i].file.path);
      }
      return imgs;
    }
  }
};
</script>