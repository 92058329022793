export default class Goal {

    constructor(numberOfFields) {
        this.Order = 0;
        this.id = 0;
        this.name = '';
        this.projectId = 0;
        this.goalTypeId = 0;
        this.amount = 0;
        this.frequency = 0;
        this.goalVerificationId = 0;
        this.othersVerification = '';
        this.goalsValuesList = [];
        for (let i = 0; i < numberOfFields; i++) {
            this.goalsValuesList.push({
                order: i,
                value: 0
            });
        }
    }
}