const GoalsValuesStatus = {
    NotStarted: 1,
    OnTime: 2,
    Late: 3,
    Finished: 4
};

const GetGoalsValuesDescription = function(goalsValuesStatus){
    switch (goalsValuesStatus) {
        case GoalsValuesStatus.NotStarted:
            return "Não iniciado";
            break;
        case GoalsValuesStatus.OnTime:
            return "No prazo";
            break;
        case GoalsValuesStatus.Late:
            return "Atrasado"
            break;
        case GoalsValuesStatus.Finished:
            return "Finalizado"
            break;
    }
}

const GetGoalsValuesStatusClass = function (status) {
    switch (status) {
        case GoalsValuesStatus.NotStarted:
            return "scheduled"
            break;
        case GoalsValuesStatus.Late:
            return "late"
            break;
        case GoalsValuesStatus.OnTime:
            return "ontime"
            break;
        case GoalsValuesStatus.Finished:
            return "finished"
            break;
    }
}

export { GoalsValuesStatus, GetGoalsValuesDescription, GetGoalsValuesStatusClass };