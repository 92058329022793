<template>
  <v-container grid-list-lg id="performance">
    <v-layout>
      <v-flex>
        <h2 class="titulo-do-bloco font-destaque color-theme-primaria">Performance Geral do Projeto</h2>
      </v-flex>
    </v-layout>
    <v-layout row wrap class="align-items-center">
      <!-- <v-flex xs12 sm6 md4> -->
      <v-flex xs12 sm6 md4>
        <v-card class="fill-height">
            <v-container mt-3>
                <SolidGaugeChart title="Metas" :value="[projectLP.percentageGoalAccomplished]" />
            </v-container>
        </v-card>
      </v-flex>
      <v-flex xs12 sm6 md4>
        <v-card class="fill-height">
          <v-container mt-3>
            <SolidGaugeChart title="Atividades" :value="[projectLP.percentageScheduleFinished]" />
          </v-container>
        </v-card>
      </v-flex>
      <v-flex xs12 md4>
        <v-card class="fill-height">
          <v-container class="fases color-theme-texto">
            <strong>Vigência prevista para o projeto</strong>
            <p>{{projectDurationDate}}</p>
            <strong>Fase do projeto</strong>
            <p>{{projectLP.projectPhaseDesc}}</p>
            <strong>Beneficiários diretos do período</strong>
            <p>{{formatterHelper.formatShortNumber(howMuchIsExpected)}}</p>
            <strong>Total de beneficiários diretos do projeto</strong>
            <p>{{formatterHelper.formatShortNumber(projectLP.beneficiariesGoal.amount)}}</p>
          </v-container>
        </v-card>
      </v-flex>
      <v-flex xs12 mt-2>
        <v-card>
          <v-container>
            <h5 class="titulo-do-cartao color-theme-texto mb-2">Beneficiários atendidos</h5>
            <div class="display-flex align-items-center">
              <div class="progress mr-3">
                <div
                  class="bg-theme-primaria medium"
                  :style="{width: formatterHelper.formatSlider(howMuchHasBeenRealizedValue, howMuchIsExpected)+'%'}"
                >{{formatterHelper.formatSlider(howMuchHasBeenRealizedValue, howMuchIsExpected)}}%</div>
              </div>
            </div>
          </v-container>
        </v-card>
      </v-flex>
    </v-layout>
  </v-container>
</template>
<script type="plain/text">
import FormatterHelper from "@/scripts/helpers/formatter.helper";
import SolidGaugeChart from "@/views/app/dashboard/component/SolidGaugeChart.vue";
import { GoalsValuesStatus } from "@/scripts/models/enums/goalsValuesStatus.enum";

export default {
  props: ["projectLP"],
  components: {
    SolidGaugeChart
  },
  data() {
    return {
      formatterHelper: FormatterHelper
    };
  },
  created() {
    this.formatterHelper = new FormatterHelper();
  },
  computed: {
    projectDurationDate() {
      return this.projectLP.finalDate
        ? this.formatterHelper.formatDate(this.projectLP.initialDate) +
            " - " +
            this.formatterHelper.formatDate(this.projectLP.finalDate)
        : this.formatterHelper.formatDate(this.projectLP.initialDate);
    },
    howMuchHasBeenRealizedValue() {
      if (
        this.projectLP.beneficiariesGoal &&
        this.projectLP.beneficiariesGoal.goalValueList
      ) {
        let result = this.projectLP.beneficiariesGoal.goalValueList.reduce(
          (acc, value) => {
            if (
              value.statusId != GoalsValuesStatus.NotStarted &&
              !value.canceled
            ) {
              return acc + value.realized;
            }
            return acc;
          },
          0
        );
        return result;
      }
      return 0;
    },
    howMuchIsExpected() {
      if (
        this.projectLP.beneficiariesGoal &&
        this.projectLP.beneficiariesGoal.goalValueList
      ) {
        let result = this.projectLP.beneficiariesGoal.goalValueList.reduce(
          (acc, value) => {
            if (
              value.statusId != GoalsValuesStatus.NotStarted &&
              !value.canceled
            ) {
              return acc + value.value;
            }
            return acc;
          },
          0
        );
        return result;
      }
      return 0;
    }
  }
};
</script>