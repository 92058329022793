import VideoHost from "../models/enums/videoHost.enum";

export default class Video {

	constructor() {
		this.id = 0;
		this.userId = 0;
		this.name = '';
		this.link = '';
		this.origin = '';
		this.hostId = VideoHost.Youtube;
	}
}