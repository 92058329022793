<template>
  <div>
    <highcharts :options="options"></highcharts>
  </div>
</template>
<script type="plain/text">
export default {
  props: {
    value: Array,
    title: String
  },
  watch: {
    value() {
      if (this) this.setData();
    }
  },
  created() {
    this.setData();
  },
  methods: {
    setData() {
      if (this && this.options) {
        this.$set(this.options, "series", [
          {
            name: "",
            innerSize: "45%",
            data: this.value,
            dataLabels: {
              format: `<div style="text-align:center">
                  <span style="font-size:25px">{y}%</span><br/>
                  <span style="font-size:12px;opacity:0.4">${this.title}</span>
                </div>`
            }
          }
        ]);
      }
    }
  },
  data: () => ({
    options: {
      chart: {
        height: 200,
        type: "solidgauge",
        style: {
          fontFamily: "Nunito"
        }
      },
      title: {
        text: null
      },
      exporting: {
        enabled: false
      },
      tooltip: {
        enabled: false
      },
      credits: {
        enabled: false
      },
      pane: {
        height: 200,
        center: ["50%", "65%"],
        size: "120%",
        startAngle: -90,
        endAngle: 90,
        background: {
          backgroundColor: "#EEE",
          innerRadius: "60%",
          outerRadius: "100%",
          shape: "arc"
        }
      },

      // the value axis
      yAxis: {
        min: 0,
        max: 100,
        lineWidth: 0,
        tickWidth: 0,
        minorTickInterval: null,
        tickAmount: 2,
        title: {
          y: -70
        },
        labels: {
          y: 16
        }
      },

      plotOptions: {
        solidgauge: {
          dataLabels: {
            y: 5,
            borderWidth: 0,
            useHTML: true
          }
        }
      }
    }
  })
};
</script>