import File from "@/scripts/models/file.model";
import ProjectAccountabilityHeroConfig from "@/scripts/models/projectAccountabilityHeroConfig.model";
import Goal from "@/scripts/models/goal.model";

export default class ProjectAccountabilityLP {
	constructor() {
		this.projectId = 0;
		this.fullName = "";
		this.projectPhaseId = 0;
		this.projectPhaseDesc = "";
		this.institutionFile = new File();
		this.initialDate = '';
        this.finalDate = '';
		this.beneficiariesGoal = new Goal();
		this.goalList = [];
		this.scheduleList = [];
		this.projectAccountabilityHeroConfig = new ProjectAccountabilityHeroConfig();
	}
}