const SchedulePeriodStatus = {
    Scheduled: 1,
    Late: 2,
    Canceled: 3,
    Finished: 4,
    Ontime: 5
};

const GetSchedulePeriodStatus = function (status) {
    switch (status) {
        case SchedulePeriodStatus.Scheduled:
            return "Agendado"
            break;
        case SchedulePeriodStatus.Late:
            return "Atrasado"
            break;
        case SchedulePeriodStatus.Canceled:
            return "Não realizado"
            break;
        case SchedulePeriodStatus.Finished:
            return "Concluído"
            break;
        case SchedulePeriodStatus.Ontime:
            return "Em Andamento"
            break;
    }
}

const GetSchedulePeriodStatusClass = function (status) {
    switch (status) {
        case SchedulePeriodStatus.Scheduled:
            return "scheduled"
            break;
        case SchedulePeriodStatus.Late:
            return "late"
            break;
        case SchedulePeriodStatus.Canceled:
            return "canceled"
            break;
        case SchedulePeriodStatus.Finished:
            return "finished"
            break;
        case SchedulePeriodStatus.Ontime:
            return "ontime"
            break;
    }
}

export { SchedulePeriodStatus, GetSchedulePeriodStatus, GetSchedulePeriodStatusClass };
