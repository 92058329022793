<template>
    <div class="video-container" :class="dialog ? 'active' : ''">
      <v-btn icon class="close" @click="close">
        <v-icon size="18">fal fa-times</v-icon>
      </v-btn>
      <iframe
        ref="videoPlayer"
        id="ytplayer"
        type="text/html"
        :src="videoLink"
        frameborder="0"
        allow="autoplay; encrypted-media"
        allowfullscreen
      />
    </div>
</template>

<script type="plain/text">
import Modal from "@/components/Modal.vue";
import CommonHelper from "@/scripts/helpers/common.helper";
import Video from "@/scripts/models/video.model";
export default {
  components: {
    Modal
  },
  data() {
    return {
      video: null,
      dialog: false
    };
  },
  computed: {
    videoLink() {
      if (this.video) {
        let videoHash = CommonHelper.validateYoutubeLink(this.video.link);
        if (videoHash && videoHash.length == 11) {
          return "https://www.youtube.com/embed/" + videoHash;
        }
      }
      return "";
    }
  },
  methods: {
    open(currentVideo) {
      this.video = currentVideo;
      this.dialog = true;
    },
    close() {
      this.$emit("close");
      this.dialog = false;
      this.video = null;
    }
  }
};
</script>
