<template>
  <div class="prestacao-contas">
    <ProjectAccountabilityHero
      :projectLP="projectLP"
      :showGoals="projectLP.goalList.length > 0"
      :showActivities="projectLP.scheduleList.length > 0"
      @scroll="scrollMeTo"
    />

    <!-- <section v-if="projectLP.goalCarousel.images.length > 0">
      <v-layout wrap>
        <v-flex xs12 md6>
          <v-carousel class="elevation-0" hide-controls>
            <v-carousel-item
              v-for="(item,i) in projectLP.goalCarousel.images"
              :key="i"
              :src="item.path"
              transition="fade-transition"
              reverse-transition="fade-transition"
            ></v-carousel-item>
          </v-carousel>
        </v-flex>
        <v-flex xs12 md6 class="metas-destaque">
          <div class="pa-5">
            <h2
              class="font-destaque color-theme-primaria"
            >{{ projectLP.goalCarousel.title ? projectLP.goalCarousel.title : '' }}</h2>
            <h4 class="color-theme-padrao my-2">{{projectLP.goalCarousel.initialDate | FormatDate("L") }} - {{projectLP.goalCarousel.initialDate | FormatDate("L") }}</h4>
            <p
              class="color-theme-texto"
            >{{ projectLP.goalCarousel.description ? projectLP.goalCarousel.description : '' }}</p>
          </div>
        </v-flex>
      </v-layout>
    </section>-->

    <main class="pt-5" style="background: #F1F2F5 !important">
      <ProjectAccountabilityOverview ref="performanceBlock" :projectLP="projectLP" />

      <ProjectAccountabilityGoalsView
        ref="goalsBlock"
        v-if="projectLP.goalList.length > 0"
        :projectLP="projectLP"
      />

      <ProjectAccountabilityActivities
        ref="activitiesBlock"
        v-if="projectLP.scheduleList.length > 0"
        :projectLP="projectLP"
      />
    </main>
    <section class="bg-theme-primaria">
      <div class="container py-5">
        <v-layout row powered-by>
          <v-layout
            color-theme-white
            t-italic
            f-size-11
            justify-center
          >Copyright © 20/Out/2019 - Instituição - Todos os direitos reservados</v-layout>
        </v-layout>
      </div>
    </section>
    <section>
      <div class="container py-5">
        <v-layout row justify-space-between powered-by>
          <v-flex t-italic f-size-11>
            Powered by
            <span>Abrace uma Causa</span>
          </v-flex>
          <v-flex t-right t-italic f-size-11>
            <a
              href="javascript:;"
              @click="$gtag('send', 'event', 'Footer', 'Termos de Uso', 'Link_Text')"
            >Termos de uso</a>
            <span class="mx-2">|</span>
            <a
              href="javascript:;"
              @click="$gtag('send', 'event', 'Footer', 'Política de Privacidade', 'Link_Text')"
            >Política de Privacidade</a>
          </v-flex>
        </v-layout>
      </div>
    </section>
  </div>
</template>


<script type="plain/text">
// Components
import ProjectAccountabilityHero from "@/components/ProjectAccountabilityHero.vue";
import ProjectAccountabilityOverview from "@/components/ProjectAccountabilityOverview.vue";
import ProjectAccountabilityGoalsView from "@/components/ProjectAccountabilityGoalsView.vue";
import ProjectAccountabilityActivities from "@/components/ProjectAccountabilityActivities.vue";
// Models
import ProjectAccountabilityLP from "@/scripts/models/projectAccountabilityLP.model";
// Services
import ProjectService from "@/scripts/services/project.service";
import CampaignService from "@/scripts/services/campaign.service";
// Enums
import { Routes } from "@/scripts/models/enums/routes.enum.js";
import { ProjectPhasesText } from "@/scripts/models/enums/projectPhases.enum.js";
import CommonHelper from "@/scripts/helpers/common.helper";

export default {
  components: {
    ProjectAccountabilityHero,
    ProjectAccountabilityOverview,
    ProjectAccountabilityGoalsView,
    ProjectAccountabilityActivities
  },
  props: ["landingPageOptions"],
  data() {
    return {
      showPolicyTerms: false,
      showServiceTerms: false,
      projectLP: new ProjectAccountabilityLP(),
      projectPhasesText: ProjectPhasesText
    };
  },
  beforeRouteEnter: (to, from, next) => {
    if (to.query.projectId && to.query.projectId != 0) {
      let projectService = new ProjectService();
      projectService
        .getProjectAccountabilityLandingPage(to.query.projectId)
        .then(function(data) {
          to.query.projectLP = data;
          if (CommonHelper.getSubdomain()) {
            new CampaignService()
              .getCampaignLandingPageConfigBySubdomain()
              .then(data => {
                to.query.navFile = data.campaign.file;
                next();
              });
          } else {
            next();
          }
        });
    } else {
      next({ name: Routes.app.DashboardPF });
    }
  },
  created() {
    document.body.scrollTop = 0; // For Safari
    document.documentElement.scrollTop = 0;
    window.scrollTo(0, 0);
    this.projectLP = this.$route.query.projectLP;
    if (this.$route.query.navFile) {
      this.$set(this.landingPageOptions, "logo", this.$route.query.navFile);
    }
    this.projectLP.projectPhaseDesc = this.getProjectPhaseName(
      this.projectLP.projectPhaseId
    );
  },
  methods: {
    getProjectPhaseName(projectPhaseId) {
      for (let projectPhase in ProjectPhasesText) {
        if (ProjectPhasesText[projectPhase].id == projectPhaseId)
          return ProjectPhasesText[projectPhase].name;
      }
    },
    scrollMeTo(refName) {
      var element = this.$refs[refName];
      if (element && element.$el) {
        var topPosition = element.$el.offsetTop;
        window.scrollTo({ top: topPosition, behavior: "smooth" });
        return true;
      }
      return false;
    }
  }
};
</script>